@import "../assets/styles/colors.scss";

.wrapper {
  padding: 30px 50px;
  background-color: $primary-light-color;
  border-radius: 0 30px 30px 0;
  box-shadow: 40px 30px 80px rgba(54, 48, 116, 0.3);

  .controls {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .top_sec,
    .mid_sec,
    .bot_sec {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    button {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px;
      background: none;
      cursor: pointer;
      transition: 0.1s linear;
      border-radius: 50%;
      padding: 2px;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }

      .img_container {
        width: 100%;
        height: 100%;
        padding: 2px;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    .muted {
      width: 20px;
      height: 20px;
    }

    .prev_next,
    .play_pause {
      box-shadow: -10px -10px 15px #ffffff, 10px 10px 10px rgba(0, 0, 0, 0.05), inset 10px 10px 10px rgba(0, 0, 0, 0.05),
        inset -10px -10px 20px #ffffff;

      .img_container {
        background: linear-gradient(327.56deg, #5d24d6 19.23%, #7e74ed 81.76%);
        box-shadow: inset 10px 10px 15px rgba(255, 255, 255, 0.2);
        filter: drop-shadow(0px 5px 10px rgba(55, 46, 152, 0.65));
        border-radius: 50%;
      }
    }

    .prev_next {
      width: 40px;
      height: 40px;
      padding: 6px;

      .img_container {
        padding: 5px;
      }
    }

    .play_pause {
      width: 60px;
      height: 60px;
      padding: 6px;

      .img_container {
        padding: 10px;
      }
    }
  }

  .progress {
    margin-top: 20px;
    margin-bottom: 10px;

    .slider {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      &:focus {
        outline: none;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
      }
      &::-moz-range-thumb {
        border: none;
      }

      width: 100%;
      background: $primary-accent-color;
      border-radius: 10px;
      box-shadow: -3px -3px 5px #ffffff, 3px 3px 5px rgba(0, 0, 0, 0.05), inset 3px 3px 5px rgba(0, 0, 0, 0.05),
        inset -3px -3px 5px #ffffff;

      @mixin track-style {
        display: flex;
        align-items: center;
        height: 10px;
        border-radius: 10px;
        box-shadow: inset -2px -2px 8px white, inset 2px 2px 8px rgba(black, 0.5);
      }

      @mixin thumb-style {
        position: relative;
        top: -50%;
        width: 20px;
        height: 20px;
        cursor: pointer;
        border-radius: 50%;
        background: linear-gradient(327.56deg, #5d24d6 19.23%, #7e74ed 81.76%);
        box-shadow: 0px 3px 5px rgba(98, 75, 242, 0.63), inset 10px 10px 15px rgba(255, 255, 255, 0.2);
      }
      &::-webkit-slider-runnable-track {
        @include track-style;
      }
      &::-webkit-slider-thumb {
        @include thumb-style;
      }
      &::-moz-range-track {
        @include track-style;
      }
      &::-moz-range-thumb {
        @include thumb-style;
      }
    }

    .times {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        display: block;
        opacity: 0.5;
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .wrapper {
    padding: 20px;

    .controls {
      display: block;

      button {
        margin: 10px;
      }
    }
  }
}
