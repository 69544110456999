.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1100px) {
  .wrapper {
    flex-direction: column;

    & > :last-child {
      border-radius: 30px;
    }
  }
}
