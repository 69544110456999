@import "../assets/styles/colors.scss";

.wrapper {
  padding: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: $primary-light-color;
  border-radius: 30px;
  box-shadow: 40px 30px 80px rgba(54, 48, 116, 0.3);
  z-index: 1;

  .img_container {
    margin-right: 25px;
    padding: 8px;
    border-radius: 15px;
    box-shadow: inset 10px 10px 10px rgba(0, 0, 0, 0.05), inset -10px -10px 20px #ffffff;
    filter: drop-shadow(-10px -10px 15px #ffffff) drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.05));

    img {
      width: 200px;
      height: 200px;
      object-fit: contain;
      border-radius: 15px;
    }
  }

  .now_playing {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-height: 200px;
    min-width: 200px;
    color: $primary-font-color;

    span {
      display: block;
    }

    .muted {
      opacity: 0.7;
      font-weight: 300;
      font-size: 12px;
    }

    .info {
      h2 {
        font-size: 22px;
        font-weight: 900;
      }

      .author {
        margin: 10px 0;
        font-weight: 300;
        font-size: 16px;
      }
    }

    .options {
      display: flex;

      button {
        cursor: pointer;
        border: none;
        margin-right: 10px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: -10px -10px 15px #ffffff, 10px 10px 10px rgba(0, 0, 0, 0.05),
          inset 10px 10px 10px rgba(0, 0, 0, 0.05), inset -10px -10px 20px #ffffff;
        transition: 0.1s linear;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .wrapper {
    flex-direction: column;
    padding: 20px;
    text-align: center;

    .img_container {
      margin: 0;
      margin-bottom: 20px;
    }

    .now_playing {
      justify-content: initial;
      min-height: unset;
    }

    .options {
      margin-top: 20px;
      justify-content: center;
    }
  }
}
